import { For, Suspense, useContext, createMemo } from 'solid-js';
import { createAsync, useParams } from '@solidjs/router';
import { Title } from '@solidjs/meta';
import { BreadcrumbItem, Breadcrumbs, Container, Page, Section } from '@troon/ui';
import { OldCardCtx } from '../../../../../providers/card';
import { gql } from '../../../../../graphql';
import { cachedQuery } from '../../../../../graphql/cached-get';
import { FacilityCard } from '../../../../../components/facility/card';
import { PageHeader } from '../../../../../components/page-header';
import type { FacilityCardFragment } from '../../../../../graphql';

export default function CardStateCourses() {
	const params = useParams<{ groupId: string; stateId: string }>();
	const card = useContext(OldCardCtx);
	const facilities = createAsync(() => getFacilities({ stateId: params.stateId, cardGroupId: params.groupId }), {
		deferStream: true,
	});

	const state = createMemo(() => card()?.card.states.find((state) => state.id === params.stateId));

	return (
		<>
			<Title>
				{card()?.card.group.name} courses in {state()?.name} | Troon Rewards
			</Title>

			<Container>
				<div class="mt-4">
					<Breadcrumbs>
						<BreadcrumbItem href="/card">2024 Troon Card</BreadcrumbItem>
						<BreadcrumbItem href={`/card/${card()?.card.group.id}`}>{card()?.card.group.name}</BreadcrumbItem>
						<BreadcrumbItem href={`/card/${card()?.card.group.id}/courses`}>Courses in {state()?.name}</BreadcrumbItem>
					</Breadcrumbs>
				</div>
				<Page>
					<PageHeader
						title={<div class="flex flex-col gap-4">Courses in {state()?.name}</div>}
						subtext={
							<p>
								Explore and book tee times at courses in {state()?.name} that are included with the{' '}
								{card()?.card.group.name}.
							</p>
						}
					/>

					<Section>
						<Suspense>
							<h2 class="text-xl font-semibold">
								{facilities()?.facilities.length} course{facilities()?.facilities.length !== 1 ? 's' : ''}
							</h2>
							<ul class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
								<For each={facilities()?.facilities as Array<FacilityCardFragment>}>
									{(facility, index) => (
										<li>
											<FacilityCard
												facility={facility}
												level="h3"
												loading={index() <= 15 ? 'eager' : 'lazy'}
												disableFavorites
											/>
										</li>
									)}
								</For>
							</ul>
						</Suspense>
					</Section>
				</Page>
			</Container>
		</>
	);
}

const facilityQuery = gql(`
query cardRegionFacilities($stateId: String!, $cardGroupId: String!) {
  facilities(stateId: $stateId cardGroupId: $cardGroupId) {
			...FacilityCard
  }
}`);

const getFacilities = cachedQuery(facilityQuery);
